<template>
    <div>
        <q-dialog
            v-model="checkconfirms"
        >
            <q-card style="width: 700px; max-width: 80vw;">
                <q-card-section class="row items-center q-pb-none">
                    <div class="text-h6">详细信息</div>
                    <q-space />
                    <q-btn icon="close" flat round dense v-close-popup />
                </q-card-section>


                <q-card-section class="q-pt-none scroll" style="max-height: 50vh">
                    <el-descriptions :column="2" border>
                        <!-- <el-descriptions-item v-for="(item,index) in logListCheckColumn" :key='index'>
                            <template slot="label">
                                {{item.label}}
                            </template>
                             
                        </el-descriptions-item> -->
                        <el-descriptions-item 
                            v-for="(item,index) in logListCheckColumn" :key='index'
                            :label="item.label" 
                            label-class-name="my-label" 
                            content-class-name="my-content"
                        >
                            <span style="word-wrap: break-word; word-break: break-all;">
                                {{`${checkDatas[item.value]}`}}
                            </span>

                        </el-descriptions-item>
                    </el-descriptions>
                   
                </q-card-section>

                <q-card-actions align="center" class="bg-white text-teal">
                    <q-btn color="primary" label="确定" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>
<script>
export default {
    props:{
        checkconfirm: {
            type: Boolean,
            required: true,
        },
        checkData: {
            type: Object,
            required: true,
        },
        //左侧循环信息
        logListCheckColumn:{
            type: Array,
            required: true,
        },
     
    },
    data() {
        return {
        }
    },
    created(){
    },
    computed:{
        checkconfirms:{
            get(){
                return this.checkconfirm
            },
            set(v){
                this.$emit('update:checkconfirm', v)
            }
        },
        checkDatas:{
            get(){
                return this.checkData
            },
            set(v){
                this.$emit('update:checkData', v)
            }
        }
    },
    methods:{
    },
    }
</script>
<style lang="less" scoped>
.mainbox{
    display: flex;
    justify-content: space-between;
    .leftbox{
        margin: 0 5px;
        width: 50%;
      
    }
    .rightbox{
        width: 50%;
    }
    .leftmainbox{
        display: flex;
        flex-direction : row;
        margin: 8px 0;
        .titbox{
            width: 20%;
            font-size: 14px;
            font-weight: bold;
            max-width: 100px;
        }
        .inputbox{
            // width: 100px;
            display: flex;
            flex-wrap: wrap;
            width: 80%;
        }
    }
}
/deep/.my-label{
    width: 100px;
}
/deep/.my-content{
    max-width: 100px;
}
</style>
